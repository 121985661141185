@import "modules/variables.scss";

@mixin sso-icon-social {
	&:before {
		background-image: url("../../../home/images/sprites/social-icons-sprite-268.png");
		background: no-repeat;
		content: " ";
		display: block;
		height: 20px;
		left: 20px;
		position: absolute;
		top: 20px;
		width: 20px;

		.svg.backgroundsize & {
			background-image: url("../../../home/images/sprites/social-icons-sprite.svg");
			background-size: 268px auto;
		}
	}
}

.re-sso-link-wrapper {
	text-align: center;
}

.re-sso-link {
	display: inline-block;
	padding-bottom: 10px;
}

.re-social-link {
	display: inline-block;
	padding-bottom: 10px;

	@media only screen and (max-width: $screen-sm-max) {
		margin: 22px 0 2px;
	}
}

.re-sso-icon {
	border-radius: 30px;
	height: 60px;
	margin-bottom: 15px;
	position: relative;
	width: 60px;
}

.re-sso-icon-sdar {
	background-image: url("//content-eecuhxg6gch7hahp.a03.azurefd.net/assets/ffcb3949-c2ee-4e81-bf22-515306af771c.png");
	background-size: 60px 60px;
}

// EBRD rebranded, now as Bridge MLS
.re-sso-icon-ebrd {
	width: 100%;
	background: url("//content-eecuhxg6gch7hahp.a03.azurefd.net/assets/b9818c9b-72db-4c09-a99f-05e47a8c529b.png") center no-repeat;
}

.re-sso-icon-remax {
	background-image: url("//content-eecuhxg6gch7hahp.a03.azurefd.net/assets/9a3ccb67-7c3b-46bb-8f23-5abe12a50cb1.png");
}

.re-sso-icon-kw {
	background-image: url("//content-eecuhxg6gch7hahp.a03.azurefd.net/assets/09b7a5ca-a2d2-4a59-a79f-1f77e9e0b5ca.png");
}

.re-sso-link-kw {
	position: relative;
	width: 65px;
	top: 20px;
}

.re-sso-icon-paar {
	background-image: url("//content-eecuhxg6gch7hahp.a03.azurefd.net/assets/f429d521-16fa-47ae-a3bf-eeeae3ac7a84.png");
}

.re-sso-icon-riar {
	background-image: url("//content-eecuhxg6gch7hahp.a03.azurefd.net/assets/3e4e8004-a79a-4fa1-bba2-1bd143cff303.png");
}


