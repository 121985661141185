@import "modules/variables.scss";

.re-homepage {

	.re-homepage-content {
		@media only screen and (max-width: $screen-md-max) {
			display: flex;
			flex-direction: column;
		}
	}

	.re-entry-tagline {
		margin: 0;
		text-align: left;

		@media only screen and (max-width: $screen-md-max) {
			margin-top: 0;
			text-align: center;
		}
	}

	.re-entry-tagline-secondary {
		font-size: $font-size-md;
		font-weight: $font-weight-normal;
		margin: 30px 0;
	}

	.re-homepage-feature-list {
		padding-left: 0;

		@media only screen and (max-width: $screen-md-max) {
			text-align: center;
			margin-bottom: 50px;
		}
	}

	.re-homepage-feature-item {
		font-size: $font-size-md;
		list-style-type: none;
		margin: 25px 0;
	}

	.re-homepage-feature-heading {
		color: $color-primary;
		font-weight: $font-weight-bold;

		@media only screen and (max-width: $screen-md-max) {
			display: block;

			&.re-homepage-feature-heading-spacer {
				display: none;
			}
		}
	}

	.js-message-control-target {
		margin: 0 0 0 auto;
		max-width: 550px;
	}

	.re-homepage-signup-message {
		border-bottom: 1px solid $color-grey;
		color: $color-grey;
		display: block;
		margin-bottom: 20px;
		margin-right: -40px;
		margin-left: -40px;
		padding-bottom: 20px;
		text-align: center;

		@media only screen and (min-width: $screen-md-min) {
			margin-top: -15px;
		}

		@media only screen and (max-width: $screen-sm-max) {
			margin-left: -20px;
			margin-right: -20px;
		}
	}

	.re-homepage-loginform-container {
		margin-bottom: 40px;

		@media only screen and (max-width: $screen-md-max) {
			order: -1;
			display: flex;
			flex-direction: column;
		}
	}

	.re-entry-alterate {
		display: none;

		@media only screen and (max-width: $screen-md-max) {
			display: block;
			order: -1;
		}
	}

	.re-entry-form-wrapper {
		margin: 0 0 0 auto;

		@media only screen and (max-width: $screen-md-max) {
			margin: 0 auto;
			width: 100%;
		}
	}

	.re-homepage-integration-wrapper {
		background: #fff;
		margin-left: calc(50% - 50vw);
		margin-right: calc(50% - 50vw);
		width: 100vw;
	}

	.re-homepage-integration-header {
		color: $color-grey;
		font-weight: $font-weight-bold;
		padding: 20px 0 10px;
		text-align: center;
	}

	.re-homepage-integration-list {
		margin: 0;
		padding: 0;
		text-align: center;
	}

	.re-homepage-integration-sprite {
		background: url("../../../home/images/sprites/sprite-integration.png");
		display: inline-block;
		height: 45px;
		margin: 5px 25px 15px;
		width: 135px;
	}

	.re-homepage-integration-clareity {
		background-position: 0 0;
		width: 76px;
	}

	.re-homepage-integration-agentachieve {
		background-position: 0 -165px;
	}

	.re-homepage-integration-rapattoni {
		background-position: 0 -110px;
	}

	.re-homepage-integration-matterport {
		background-position: 0 -55px;
	}
}
