@import "modules/variables.scss";

body {
	background: $color-white;
}

.re-anonymous-theme {
    .re-footer {
        background: $color-black;
    }

    .re-unsubscribe-wrapper {
        text-align: center;

        .re-unsubscribe-wrapper-header {
            color: $color-black;
        }
    }

    .re-entry-signup {
        margin: $spacing-xl 0 $spacing-lg;
    }
    // signup with pricing page
    .re-entry-signup-pricing {
        padding-top: 40px;

        .re-entry-tagline {
            margin: 0 auto;
            max-width: 550px;

            .re-entry-tagline-primary {
                font-size: 30px;
            }

            .re-entry-tagline-secondary {
                font-size: $font-size-md;
            }
        }

        .re-or-divider {
            width: 100%;
            max-width: 1300px;
            margin: 15px auto;

            &:before {
                left: 53%;
                width: 50%;
            }

            &:after {
                border-top: 1px solid #e4e4e4;
                content: "";
                height: 0;
                right: 53%;
                position: absolute;
                top: 50%;
                width: 50%;
            }

            .re-or-divider-inner {
                background: none;
            }
        }

        .re-entry-options-forgot-password {
            padding: 12px 0;
        }

        .re-sso-link {
            max-width: 100%;
        }

        .re-sso-images {
            max-width: 100%;
            max-height: 100%;
            flex: 0 0 auto;
        }

        .re-sso-logo {
            height: 60px;
            position: relative;
            background: no-repeat center center;
            padding: 10px;
            align-items: center;
            display: flex;
        }

        .re-sso-logo-sfar {
            max-width: 140%;
            margin-left: -5px;
        }

        .re-sso-logo-kw {
            max-width: 50%;
            margin: 0 auto
        }

        .re-sso-link-container .re-signup-pricing-label {
            margin: 70px auto 50px;
        }
    }
    //email unsubscribe page
    .re-entry-unsubscribe {
        .re-entry-form-wrapper {
            max-width: 600px;
            text-align: center;
            padding-bottom: 50px;
        }

        .re-entry-tagline {
            margin: 30px auto 50px;
            color: $color-black;
            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
        }

        .re-entry-tagline-secondary, .re-entry-options {
            margin: 50px auto;
        }

        p {
            font-size: $font-size-md;
        }
    }
    // styles for /signup page
    .re-header {
        font-size: $font-size-lg;
        font-weight: $font-weight-semibold;
        text-align: center;

        @media #{$mediaquery-sm-min} {
            font-size: $font-size-xl;
        }
    }

    .re-sub-header {
        font-size: $font-size-md;
        text-align: center;
        padding-bottom: $spacing-xl;
        margin-bottom: $spacing-lg;

        @media #{$mediaquery-sm-min} {
            font-size: $font-size-lg;
        }
    }

    .re-privacy {
        font-size: $font-size-xs;
        text-align: center;
        margin-top: $spacing-sm;
    }

    .re-signup-legal-link {
        color: $color-black;
        text-decoration: underline;
    }
}