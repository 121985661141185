@import "modules/variables.scss";
@import "partials/grid";

$entryFormTextColor: #a8acb9;

.re-entry-tagline {
	margin: 0 0 50px;
	text-align: center;

	@media only screen and (max-width: $screen-sm-max) {
		margin: 0 20px 20px;
	}
}

.re-entry-tagline-primary {
	font-size: $font-size-xl;
	font-weight: $font-weight-semibold;
	margin: 0 0 0.3em;
	line-height: 1.4;

	@media only screen and (max-width: $screen-sm-max) {
		font-size: $font-size-lg;
	}
}

.re-entry-tagline-secondary {
	font-size: $font-size-lg;
	font-weight: $font-weight-light;
	margin-top: 0;

	@media only screen and (max-width: $screen-sm-max) {
		font-size: $font-size-md;
	}
}

.re-entry-form-wrapper {
	background: #fff;
	border-radius: $border-radius-large;
	box-sizing: border-box;
	color: $rGreyDarkest;
	margin: 0 auto;
	max-width: 550px;
	padding: 35px 40px 30px;

	@media only screen and (max-width: $screen-md-max) {
		margin: 0 auto;
	}

	@media only screen and (max-width: $screen-sm-max) {
		padding: 25px 20px 20px;
	}

	.formV2 {
		input[type="text"],
		input[type="tel"],
		input[type="number"],
		input[type="password"],
		input[type="email"],
		select,
		textarea {
			background: $color-white;
			border-color: $color-black;
		}
	}
}

.re-entry-social-options {
	margin-bottom: 20px;
}

.re-or-divider {
	margin: 15px 0 30px;
	overflow: hidden;
	position: relative;
	text-align: center;

	&:before {
		border-top: 1px solid #e4e4e4;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 50%;
		width: 9999em;
	}
}

.re-or-divider-inner {
	background: #fff;
	font-weight: $font-weight-bold;
	display: inline-block;
	padding: 0 12px;
	position: relative;
}

.re-entry-policies {
	margin: 30px 50px 0;
	text-align: center;

	@media only screen and (max-width: $screen-sm-max) {
		margin: 30px 0 0;
	}

	a {
		white-space: nowrap;
	}
}

.re-entry-options {
	margin-top: 30px;

	label {
		color: $color-grey;
	}
}

.re-entry-options-forgot-password {
	float: right;
	color: $color-grey;

	@media only screen and (max-width: $screen-sm-max) {
		float: none;
		text-align: center;
	}
}

.re-entry-alterate {
	margin: 20px auto 0;
	max-width: 350px;
}


